import { default as _2022_45impact4iEJ5LjEGRMeta } from "/opt/build/repo/packages/member-webapp/pages/2022-impact.vue?macro=true";
import { default as _91id_93HCRLrFGlybMeta } from "/opt/build/repo/packages/member-webapp/pages/confirm-advisor/[id].vue?macro=true";
import { default as agreementqg9ZPgQG2EMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/agreement.vue?macro=true";
import { default as finish_45non_45domx4Lqf0oFgZMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish-non-dom.vue?macro=true";
import { default as finish4u0GOmDOOZMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish.vue?macro=true";
import { default as gift_45aid_45signheqoMWc97OMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid-sign.vue?macro=true";
import { default as gift_45aidsyMQgEvp5zMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid.vue?macro=true";
import { default as indexy21zsM3EqxMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/index.vue?macro=true";
import { default as sign17ivmKkM4dMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/sign.vue?macro=true";
import { default as transfer_45detailsRGgrLwGI0YMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/transfer-details.vue?macro=true";
import { default as asset8g6g9BKB8BMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/asset.vue?macro=true";
import { default as amountmXyJ2Li349Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/amount.vue?macro=true";
import { default as asset_45owner9uBISkYFdqMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/asset-owner.vue?macro=true";
import { default as bankQEHL7tfgIJMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/bank.vue?macro=true";
import { default as confirmDGpYNUnGwhMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/confirm.vue?macro=true";
import { default as donor1mQjD8kPhQMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/donor.vue?macro=true";
import { default as payment_45typeSfqbCSEV5sMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/payment-type.vue?macro=true";
import { default as taxW63GRPbvMdMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/tax.vue?macro=true";
import { default as daf_45accountybHfCVTObsMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/daf-account.vue?macro=true";
import { default as indexGUwuInk5aWMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/index.vue?macro=true";
import { default as asset_45ownerugIvqNhW5nMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/asset-owner.vue?macro=true";
import { default as confirmv0OzZ5Bn8XMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/confirm.vue?macro=true";
import { default as donorj5pP7yzcS0Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/donor.vue?macro=true";
import { default as restrictionsS4SMAtRjE0Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/restrictions.vue?macro=true";
import { default as security_45detailsxIHz9qTRmeMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/security-details.vue?macro=true";
import { default as taxn0ZzmFF6vMMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/tax.vue?macro=true";
import { default as finishAhbKRVxXEtMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/get-in-touch/finish.vue?macro=true";
import { default as news6A4eHU9CEMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/get-in-touch/new.vue?macro=true";
import { default as indexGfoPbvtrhHMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/index.vue?macro=true";
import { default as indexxKlBnawI91Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/index.vue?macro=true";
import { default as indexRquAhbacONMeta } from "/opt/build/repo/packages/member-webapp/pages/error/index.vue?macro=true";
import { default as InternalServerErrorwMbiUEEkR0Meta } from "/opt/build/repo/packages/member-webapp/pages/error/InternalServerError.vue?macro=true";
import { default as index8WKegJyyGrMeta } from "/opt/build/repo/packages/member-webapp/pages/gift-aids/[id]/index.vue?macro=true";
import { default as new7gfUrLT66pMeta } from "/opt/build/repo/packages/member-webapp/pages/grant/new.vue?macro=true";
import { default as indexZtiFYZBwUGMeta } from "/opt/build/repo/packages/member-webapp/pages/grants/[id]/index.vue?macro=true";
import { default as index8kWcsKo0uyMeta } from "/opt/build/repo/packages/member-webapp/pages/index.vue?macro=true";
import { default as maintenancebhQwqiQGaXMeta } from "/opt/build/repo/packages/member-webapp/pages/maintenance.vue?macro=true";
import { default as _91charity_name_93AQ1dEmcKD4Meta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/[charity_name].vue?macro=true";
import { default as how_45we_45measure_45impactKJxDK7ZiSVMeta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/how-we-measure-impact.vue?macro=true";
import { default as indexbjmyHkDaqXMeta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/index.vue?macro=true";
import { default as no_45advised_45dafsxnNieVOFlbMeta } from "/opt/build/repo/packages/member-webapp/pages/no-advised-dafs.vue?macro=true";
import { default as cause_45areasiY20QoWGV8Meta } from "/opt/build/repo/packages/member-webapp/pages/onboarding/cause-areas.vue?macro=true";
import { default as indexmnlSkKA7sEMeta } from "/opt/build/repo/packages/member-webapp/pages/onboarding/index.vue?macro=true";
import { default as profileLuBuCbvfCDMeta } from "/opt/build/repo/packages/member-webapp/pages/onboarding/profile.vue?macro=true";
import { default as welcome_45callH3ff7z8s7aMeta } from "/opt/build/repo/packages/member-webapp/pages/onboarding/welcome-call.vue?macro=true";
import { default as indexGdxpAPBgXlMeta } from "/opt/build/repo/packages/member-webapp/pages/our-recommendations/index.vue?macro=true";
import { default as password_45change4CoESWnAKbMeta } from "/opt/build/repo/packages/member-webapp/pages/password-change.vue?macro=true";
import { default as indexlOHRVA6LayMeta } from "/opt/build/repo/packages/member-webapp/pages/pledge/index.vue?macro=true";
import { default as newhx02RuaIrVMeta } from "/opt/build/repo/packages/member-webapp/pages/pledge/new.vue?macro=true";
import { default as indexoug8LzkzCcMeta } from "/opt/build/repo/packages/member-webapp/pages/pledges/index.vue?macro=true";
import { default as indexdkj3Xy2qbKMeta } from "/opt/build/repo/packages/member-webapp/pages/settings/index.vue?macro=true";
import { default as signinQ9RmiewYhwMeta } from "/opt/build/repo/packages/member-webapp/pages/signin.vue?macro=true";
import { default as indexXE3iq9qyBlMeta } from "/opt/build/repo/packages/member-webapp/pages/support-fp/index.vue?macro=true";
import { default as technical_45problem3l1KsOLqahMeta } from "/opt/build/repo/packages/member-webapp/pages/technical-problem.vue?macro=true";
import { default as indexRs2w5dVFN6Meta } from "/opt/build/repo/packages/member-webapp/pages/transfer-your-daf/index.vue?macro=true";
export default [
  {
    name: _2022_45impact4iEJ5LjEGRMeta?.name ?? "2022-impact",
    path: _2022_45impact4iEJ5LjEGRMeta?.path ?? "/2022-impact",
    meta: _2022_45impact4iEJ5LjEGRMeta || {},
    alias: _2022_45impact4iEJ5LjEGRMeta?.alias || [],
    redirect: _2022_45impact4iEJ5LjEGRMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/2022-impact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HCRLrFGlybMeta?.name ?? "confirm-advisor-id",
    path: _91id_93HCRLrFGlybMeta?.path ?? "/confirm-advisor/:id()",
    meta: _91id_93HCRLrFGlybMeta || {},
    alias: _91id_93HCRLrFGlybMeta?.alias || [],
    redirect: _91id_93HCRLrFGlybMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/confirm-advisor/[id].vue").then(m => m.default || m)
  },
  {
    name: agreementqg9ZPgQG2EMeta?.name ?? "contributions-id-agreement",
    path: agreementqg9ZPgQG2EMeta?.path ?? "/contributions/:id()/agreement",
    meta: agreementqg9ZPgQG2EMeta || {},
    alias: agreementqg9ZPgQG2EMeta?.alias || [],
    redirect: agreementqg9ZPgQG2EMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: finish_45non_45domx4Lqf0oFgZMeta?.name ?? "contributions-id-finish-non-dom",
    path: finish_45non_45domx4Lqf0oFgZMeta?.path ?? "/contributions/:id()/finish-non-dom",
    meta: finish_45non_45domx4Lqf0oFgZMeta || {},
    alias: finish_45non_45domx4Lqf0oFgZMeta?.alias || [],
    redirect: finish_45non_45domx4Lqf0oFgZMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish-non-dom.vue").then(m => m.default || m)
  },
  {
    name: finish4u0GOmDOOZMeta?.name ?? "contributions-id-finish",
    path: finish4u0GOmDOOZMeta?.path ?? "/contributions/:id()/finish",
    meta: finish4u0GOmDOOZMeta || {},
    alias: finish4u0GOmDOOZMeta?.alias || [],
    redirect: finish4u0GOmDOOZMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish.vue").then(m => m.default || m)
  },
  {
    name: gift_45aid_45signheqoMWc97OMeta?.name ?? "contributions-id-gift-aid-sign",
    path: gift_45aid_45signheqoMWc97OMeta?.path ?? "/contributions/:id()/gift-aid-sign",
    meta: gift_45aid_45signheqoMWc97OMeta || {},
    alias: gift_45aid_45signheqoMWc97OMeta?.alias || [],
    redirect: gift_45aid_45signheqoMWc97OMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid-sign.vue").then(m => m.default || m)
  },
  {
    name: gift_45aidsyMQgEvp5zMeta?.name ?? "contributions-id-gift-aid",
    path: gift_45aidsyMQgEvp5zMeta?.path ?? "/contributions/:id()/gift-aid",
    meta: gift_45aidsyMQgEvp5zMeta || {},
    alias: gift_45aidsyMQgEvp5zMeta?.alias || [],
    redirect: gift_45aidsyMQgEvp5zMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid.vue").then(m => m.default || m)
  },
  {
    name: indexy21zsM3EqxMeta?.name ?? "contributions-id",
    path: indexy21zsM3EqxMeta?.path ?? "/contributions/:id()",
    meta: indexy21zsM3EqxMeta || {},
    alias: indexy21zsM3EqxMeta?.alias || [],
    redirect: indexy21zsM3EqxMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: sign17ivmKkM4dMeta?.name ?? "contributions-id-sign",
    path: sign17ivmKkM4dMeta?.path ?? "/contributions/:id()/sign",
    meta: sign17ivmKkM4dMeta || {},
    alias: sign17ivmKkM4dMeta?.alias || [],
    redirect: sign17ivmKkM4dMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/sign.vue").then(m => m.default || m)
  },
  {
    name: transfer_45detailsRGgrLwGI0YMeta?.name ?? "contributions-id-transfer-details",
    path: transfer_45detailsRGgrLwGI0YMeta?.path ?? "/contributions/:id()/transfer-details",
    meta: transfer_45detailsRGgrLwGI0YMeta || {},
    alias: transfer_45detailsRGgrLwGI0YMeta?.alias || [],
    redirect: transfer_45detailsRGgrLwGI0YMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/transfer-details.vue").then(m => m.default || m)
  },
  {
    name: asset8g6g9BKB8BMeta?.name ?? "daf-accounts-gift_account_id-contribution-asset",
    path: asset8g6g9BKB8BMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/asset",
    meta: asset8g6g9BKB8BMeta || {},
    alias: asset8g6g9BKB8BMeta?.alias || [],
    redirect: asset8g6g9BKB8BMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/asset.vue").then(m => m.default || m)
  },
  {
    name: amountmXyJ2Li349Meta?.name ?? "daf-accounts-gift_account_id-contribution-cash-amount",
    path: amountmXyJ2Li349Meta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/amount",
    meta: amountmXyJ2Li349Meta || {},
    alias: amountmXyJ2Li349Meta?.alias || [],
    redirect: amountmXyJ2Li349Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/amount.vue").then(m => m.default || m)
  },
  {
    name: asset_45owner9uBISkYFdqMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-asset-owner",
    path: asset_45owner9uBISkYFdqMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/asset-owner",
    meta: asset_45owner9uBISkYFdqMeta || {},
    alias: asset_45owner9uBISkYFdqMeta?.alias || [],
    redirect: asset_45owner9uBISkYFdqMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/asset-owner.vue").then(m => m.default || m)
  },
  {
    name: bankQEHL7tfgIJMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-bank",
    path: bankQEHL7tfgIJMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/bank",
    meta: bankQEHL7tfgIJMeta || {},
    alias: bankQEHL7tfgIJMeta?.alias || [],
    redirect: bankQEHL7tfgIJMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/bank.vue").then(m => m.default || m)
  },
  {
    name: confirmDGpYNUnGwhMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-confirm",
    path: confirmDGpYNUnGwhMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/confirm",
    meta: confirmDGpYNUnGwhMeta || {},
    alias: confirmDGpYNUnGwhMeta?.alias || [],
    redirect: confirmDGpYNUnGwhMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/confirm.vue").then(m => m.default || m)
  },
  {
    name: donor1mQjD8kPhQMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-donor",
    path: donor1mQjD8kPhQMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/donor",
    meta: donor1mQjD8kPhQMeta || {},
    alias: donor1mQjD8kPhQMeta?.alias || [],
    redirect: donor1mQjD8kPhQMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/donor.vue").then(m => m.default || m)
  },
  {
    name: payment_45typeSfqbCSEV5sMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-payment-type",
    path: payment_45typeSfqbCSEV5sMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/payment-type",
    meta: payment_45typeSfqbCSEV5sMeta || {},
    alias: payment_45typeSfqbCSEV5sMeta?.alias || [],
    redirect: payment_45typeSfqbCSEV5sMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/payment-type.vue").then(m => m.default || m)
  },
  {
    name: taxW63GRPbvMdMeta?.name ?? "daf-accounts-gift_account_id-contribution-cash-tax",
    path: taxW63GRPbvMdMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/cash/tax",
    meta: taxW63GRPbvMdMeta || {},
    alias: taxW63GRPbvMdMeta?.alias || [],
    redirect: taxW63GRPbvMdMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/cash/tax.vue").then(m => m.default || m)
  },
  {
    name: daf_45accountybHfCVTObsMeta?.name ?? "daf-accounts-gift_account_id-contribution-daf-account",
    path: daf_45accountybHfCVTObsMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/daf-account",
    meta: daf_45accountybHfCVTObsMeta || {},
    alias: daf_45accountybHfCVTObsMeta?.alias || [],
    redirect: daf_45accountybHfCVTObsMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/daf-account.vue").then(m => m.default || m)
  },
  {
    name: indexGUwuInk5aWMeta?.name ?? "daf-accounts-gift_account_id-contribution",
    path: indexGUwuInk5aWMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution",
    meta: indexGUwuInk5aWMeta || {},
    alias: indexGUwuInk5aWMeta?.alias || [],
    redirect: indexGUwuInk5aWMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/index.vue").then(m => m.default || m)
  },
  {
    name: asset_45ownerugIvqNhW5nMeta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-asset-owner",
    path: asset_45ownerugIvqNhW5nMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/asset-owner",
    meta: asset_45ownerugIvqNhW5nMeta || {},
    alias: asset_45ownerugIvqNhW5nMeta?.alias || [],
    redirect: asset_45ownerugIvqNhW5nMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/asset-owner.vue").then(m => m.default || m)
  },
  {
    name: confirmv0OzZ5Bn8XMeta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-confirm",
    path: confirmv0OzZ5Bn8XMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/confirm",
    meta: confirmv0OzZ5Bn8XMeta || {},
    alias: confirmv0OzZ5Bn8XMeta?.alias || [],
    redirect: confirmv0OzZ5Bn8XMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/confirm.vue").then(m => m.default || m)
  },
  {
    name: donorj5pP7yzcS0Meta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-donor",
    path: donorj5pP7yzcS0Meta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/donor",
    meta: donorj5pP7yzcS0Meta || {},
    alias: donorj5pP7yzcS0Meta?.alias || [],
    redirect: donorj5pP7yzcS0Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/donor.vue").then(m => m.default || m)
  },
  {
    name: restrictionsS4SMAtRjE0Meta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-restrictions",
    path: restrictionsS4SMAtRjE0Meta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/restrictions",
    meta: restrictionsS4SMAtRjE0Meta || {},
    alias: restrictionsS4SMAtRjE0Meta?.alias || [],
    redirect: restrictionsS4SMAtRjE0Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/restrictions.vue").then(m => m.default || m)
  },
  {
    name: security_45detailsxIHz9qTRmeMeta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-security-details",
    path: security_45detailsxIHz9qTRmeMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/security-details",
    meta: security_45detailsxIHz9qTRmeMeta || {},
    alias: security_45detailsxIHz9qTRmeMeta?.alias || [],
    redirect: security_45detailsxIHz9qTRmeMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/security-details.vue").then(m => m.default || m)
  },
  {
    name: taxn0ZzmFF6vMMeta?.name ?? "daf-accounts-gift_account_id-contribution-public-securities-tax",
    path: taxn0ZzmFF6vMMeta?.path ?? "/daf-accounts/:gift_account_id()/contribution/public-securities/tax",
    meta: taxn0ZzmFF6vMMeta || {},
    alias: taxn0ZzmFF6vMMeta?.alias || [],
    redirect: taxn0ZzmFF6vMMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/contribution/public-securities/tax.vue").then(m => m.default || m)
  },
  {
    name: finishAhbKRVxXEtMeta?.name ?? "daf-accounts-gift_account_id-get-in-touch-finish",
    path: finishAhbKRVxXEtMeta?.path ?? "/daf-accounts/:gift_account_id()/get-in-touch/finish",
    meta: finishAhbKRVxXEtMeta || {},
    alias: finishAhbKRVxXEtMeta?.alias || [],
    redirect: finishAhbKRVxXEtMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/get-in-touch/finish.vue").then(m => m.default || m)
  },
  {
    name: news6A4eHU9CEMeta?.name ?? "daf-accounts-gift_account_id-get-in-touch-new",
    path: news6A4eHU9CEMeta?.path ?? "/daf-accounts/:gift_account_id()/get-in-touch/new",
    meta: news6A4eHU9CEMeta || {},
    alias: news6A4eHU9CEMeta?.alias || [],
    redirect: news6A4eHU9CEMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/get-in-touch/new.vue").then(m => m.default || m)
  },
  {
    name: indexGfoPbvtrhHMeta?.name ?? "daf-accounts-gift_account_id",
    path: indexGfoPbvtrhHMeta?.path ?? "/daf-accounts/:gift_account_id()",
    meta: indexGfoPbvtrhHMeta || {},
    alias: indexGfoPbvtrhHMeta?.alias || [],
    redirect: indexGfoPbvtrhHMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxKlBnawI91Meta?.name ?? "daf-accounts",
    path: indexxKlBnawI91Meta?.path ?? "/daf-accounts",
    meta: indexxKlBnawI91Meta || {},
    alias: indexxKlBnawI91Meta?.alias || [],
    redirect: indexxKlBnawI91Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexRquAhbacONMeta?.name ?? "error",
    path: indexRquAhbacONMeta?.path ?? "/error",
    meta: indexRquAhbacONMeta || {},
    alias: indexRquAhbacONMeta?.alias || [],
    redirect: indexRquAhbacONMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: InternalServerErrorwMbiUEEkR0Meta?.name ?? "error-InternalServerError",
    path: InternalServerErrorwMbiUEEkR0Meta?.path ?? "/error/InternalServerError",
    meta: InternalServerErrorwMbiUEEkR0Meta || {},
    alias: InternalServerErrorwMbiUEEkR0Meta?.alias || [],
    redirect: InternalServerErrorwMbiUEEkR0Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/error/InternalServerError.vue").then(m => m.default || m)
  },
  {
    name: index8WKegJyyGrMeta?.name ?? "gift-aids-id",
    path: index8WKegJyyGrMeta?.path ?? "/gift-aids/:id()",
    meta: index8WKegJyyGrMeta || {},
    alias: index8WKegJyyGrMeta?.alias || [],
    redirect: index8WKegJyyGrMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/gift-aids/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: new7gfUrLT66pMeta?.name ?? "grant-new",
    path: new7gfUrLT66pMeta?.path ?? "/grant/new",
    meta: new7gfUrLT66pMeta || {},
    alias: new7gfUrLT66pMeta?.alias || [],
    redirect: new7gfUrLT66pMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/grant/new.vue").then(m => m.default || m)
  },
  {
    name: indexZtiFYZBwUGMeta?.name ?? "grants-id",
    path: indexZtiFYZBwUGMeta?.path ?? "/grants/:id()",
    meta: indexZtiFYZBwUGMeta || {},
    alias: indexZtiFYZBwUGMeta?.alias || [],
    redirect: indexZtiFYZBwUGMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/grants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index8kWcsKo0uyMeta?.name ?? "index",
    path: index8kWcsKo0uyMeta?.path ?? "/",
    meta: index8kWcsKo0uyMeta || {},
    alias: index8kWcsKo0uyMeta?.alias || [],
    redirect: index8kWcsKo0uyMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancebhQwqiQGaXMeta?.name ?? "maintenance",
    path: maintenancebhQwqiQGaXMeta?.path ?? "/maintenance",
    meta: maintenancebhQwqiQGaXMeta || {},
    alias: maintenancebhQwqiQGaXMeta?.alias || [],
    redirect: maintenancebhQwqiQGaXMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91charity_name_93AQ1dEmcKD4Meta?.name ?? "my-giving-charity_name",
    path: _91charity_name_93AQ1dEmcKD4Meta?.path ?? "/my-giving/:charity_name()",
    meta: _91charity_name_93AQ1dEmcKD4Meta || {},
    alias: _91charity_name_93AQ1dEmcKD4Meta?.alias || [],
    redirect: _91charity_name_93AQ1dEmcKD4Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/[charity_name].vue").then(m => m.default || m)
  },
  {
    name: how_45we_45measure_45impactKJxDK7ZiSVMeta?.name ?? "my-giving-how-we-measure-impact",
    path: how_45we_45measure_45impactKJxDK7ZiSVMeta?.path ?? "/my-giving/how-we-measure-impact",
    meta: how_45we_45measure_45impactKJxDK7ZiSVMeta || {},
    alias: how_45we_45measure_45impactKJxDK7ZiSVMeta?.alias || [],
    redirect: how_45we_45measure_45impactKJxDK7ZiSVMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/how-we-measure-impact.vue").then(m => m.default || m)
  },
  {
    name: indexbjmyHkDaqXMeta?.name ?? "my-giving",
    path: indexbjmyHkDaqXMeta?.path ?? "/my-giving",
    meta: indexbjmyHkDaqXMeta || {},
    alias: indexbjmyHkDaqXMeta?.alias || [],
    redirect: indexbjmyHkDaqXMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/index.vue").then(m => m.default || m)
  },
  {
    name: no_45advised_45dafsxnNieVOFlbMeta?.name ?? "no-advised-dafs",
    path: no_45advised_45dafsxnNieVOFlbMeta?.path ?? "/no-advised-dafs",
    meta: no_45advised_45dafsxnNieVOFlbMeta || {},
    alias: no_45advised_45dafsxnNieVOFlbMeta?.alias || [],
    redirect: no_45advised_45dafsxnNieVOFlbMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/no-advised-dafs.vue").then(m => m.default || m)
  },
  {
    name: cause_45areasiY20QoWGV8Meta?.name ?? "onboarding-cause-areas",
    path: cause_45areasiY20QoWGV8Meta?.path ?? "/onboarding/cause-areas",
    meta: cause_45areasiY20QoWGV8Meta || {},
    alias: cause_45areasiY20QoWGV8Meta?.alias || [],
    redirect: cause_45areasiY20QoWGV8Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/onboarding/cause-areas.vue").then(m => m.default || m)
  },
  {
    name: indexmnlSkKA7sEMeta?.name ?? "onboarding",
    path: indexmnlSkKA7sEMeta?.path ?? "/onboarding",
    meta: indexmnlSkKA7sEMeta || {},
    alias: indexmnlSkKA7sEMeta?.alias || [],
    redirect: indexmnlSkKA7sEMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: profileLuBuCbvfCDMeta?.name ?? "onboarding-profile",
    path: profileLuBuCbvfCDMeta?.path ?? "/onboarding/profile",
    meta: profileLuBuCbvfCDMeta || {},
    alias: profileLuBuCbvfCDMeta?.alias || [],
    redirect: profileLuBuCbvfCDMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/onboarding/profile.vue").then(m => m.default || m)
  },
  {
    name: welcome_45callH3ff7z8s7aMeta?.name ?? "onboarding-welcome-call",
    path: welcome_45callH3ff7z8s7aMeta?.path ?? "/onboarding/welcome-call",
    meta: welcome_45callH3ff7z8s7aMeta || {},
    alias: welcome_45callH3ff7z8s7aMeta?.alias || [],
    redirect: welcome_45callH3ff7z8s7aMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/onboarding/welcome-call.vue").then(m => m.default || m)
  },
  {
    name: indexGdxpAPBgXlMeta?.name ?? "our-recommendations",
    path: indexGdxpAPBgXlMeta?.path ?? "/our-recommendations",
    meta: indexGdxpAPBgXlMeta || {},
    alias: indexGdxpAPBgXlMeta?.alias || [],
    redirect: indexGdxpAPBgXlMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/our-recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: password_45change4CoESWnAKbMeta?.name ?? "password-change",
    path: password_45change4CoESWnAKbMeta?.path ?? "/password-change",
    meta: password_45change4CoESWnAKbMeta || {},
    alias: password_45change4CoESWnAKbMeta?.alias || [],
    redirect: password_45change4CoESWnAKbMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/password-change.vue").then(m => m.default || m)
  },
  {
    name: indexlOHRVA6LayMeta?.name ?? "pledge",
    path: indexlOHRVA6LayMeta?.path ?? "/pledge",
    meta: indexlOHRVA6LayMeta || {},
    alias: indexlOHRVA6LayMeta?.alias || [],
    redirect: indexlOHRVA6LayMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledge/index.vue").then(m => m.default || m)
  },
  {
    name: newhx02RuaIrVMeta?.name ?? "pledge-new",
    path: newhx02RuaIrVMeta?.path ?? "/pledge/new",
    meta: newhx02RuaIrVMeta || {},
    alias: newhx02RuaIrVMeta?.alias || [],
    redirect: newhx02RuaIrVMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledge/new.vue").then(m => m.default || m)
  },
  {
    name: indexoug8LzkzCcMeta?.name ?? "pledges",
    path: indexoug8LzkzCcMeta?.path ?? "/pledges",
    meta: indexoug8LzkzCcMeta || {},
    alias: indexoug8LzkzCcMeta?.alias || [],
    redirect: indexoug8LzkzCcMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: indexdkj3Xy2qbKMeta?.name ?? "settings",
    path: indexdkj3Xy2qbKMeta?.path ?? "/settings",
    meta: indexdkj3Xy2qbKMeta || {},
    alias: indexdkj3Xy2qbKMeta?.alias || [],
    redirect: indexdkj3Xy2qbKMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: signinQ9RmiewYhwMeta?.name ?? "signin",
    path: signinQ9RmiewYhwMeta?.path ?? "/signin",
    meta: signinQ9RmiewYhwMeta || {},
    alias: signinQ9RmiewYhwMeta?.alias || [],
    redirect: signinQ9RmiewYhwMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: indexXE3iq9qyBlMeta?.name ?? "support-fp",
    path: indexXE3iq9qyBlMeta?.path ?? "/support-fp",
    meta: indexXE3iq9qyBlMeta || {},
    alias: indexXE3iq9qyBlMeta?.alias || [],
    redirect: indexXE3iq9qyBlMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/support-fp/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45problem3l1KsOLqahMeta?.name ?? "technical-problem",
    path: technical_45problem3l1KsOLqahMeta?.path ?? "/technical-problem",
    meta: technical_45problem3l1KsOLqahMeta || {},
    alias: technical_45problem3l1KsOLqahMeta?.alias || [],
    redirect: technical_45problem3l1KsOLqahMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/technical-problem.vue").then(m => m.default || m)
  },
  {
    name: indexRs2w5dVFN6Meta?.name ?? "transfer-your-daf",
    path: indexRs2w5dVFN6Meta?.path ?? "/transfer-your-daf",
    meta: indexRs2w5dVFN6Meta || {},
    alias: indexRs2w5dVFN6Meta?.alias || [],
    redirect: indexRs2w5dVFN6Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/transfer-your-daf/index.vue").then(m => m.default || m)
  }
]